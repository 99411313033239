export const VALIDATIONS = {
    EMAIL: [
        { required: true, message: 'Campo obbligatorio' },
        { pattern: new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/), message: 'Email non valida'}
    ],
    PHONE: [
        { required: true, message: 'Campo obbligatorio' },
        { pattern: new RegExp(/^[0-9]{10,}$/), message: 'Formato non valido'}
    ],
    CAP: [
        // { required: true, message: 'Campo obbligatorio' },
        { len: 5, message: 'La lunghezza deve essere 5 caratteri' },
        { pattern: new RegExp(/^[0-9]+$/), message: 'Formato non valido'}
    ],
    PROVINCE: [
        // { required: true, message: 'Campo obbligatorio' },
        { max: 2, message: 'La lunghezza deve essere 2 caratteri' },
    ],
    PHISCAL_CODE: [
        { required: true, message: 'Campo obbligatorio' },
        { len: 16, message: 'Il campo deve essere lungo 16 caratteri' },
    ],

} 