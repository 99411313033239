import axios, { AxiosError } from "axios";
import { UserService } from "./UserService";

export class HttpService {

    static readonly BASEHTTPURL = (window.location.hostname === 'www.ilmiocaf.cloud' ? '/v1/api' : '//localhost/api');


    /**
     * Set axios needed params
     * For example Authorization header
     */
    static setAxiosInterceptor() {
        // Add a request interceptor
        axios.interceptors.request.use(function (config) {

            // Set Authorization Header
            const accessToken = UserService.getAccessToken();
            if(accessToken) {
                config.headers['Authorization'] = `Bearer ${accessToken}`;
            }


            config.headers['Accept'] = 'application/json';

            if(!config.headers['Content-Type'] ) {    
                config.headers['Content-Type'] = 'application/json;charset=UTF-8';
            }

            return config;
        }, function (error) {
            return Promise.reject(error);
        });


        // Add a response interceptor
        axios.interceptors.response.use(response => {
            return response;
         }, error => {

            if ( !error.request.responseURL.contains('/api/login') && error.response.status === 401) {
                // Remove access token
                UserService.clearUserTokens();

                // Redirect to login
                window.location.href = '/';
                
           }

           throw new AxiosError(error.response);
         });
    }

    /**
     * Make http GET request
     * @param url 
     * @param getParams 
     * @param useBaseHttpUrl 
     * @returns 
     */
    static async get(url: string, params: Record<string, any>, useBaseHttpUrl: boolean = true) {

        const options = {
            url: (useBaseHttpUrl ? this.BASEHTTPURL : '') +  url,
            method: 'GET',
            data: params,
          };
          
        return await axios(options);
    }

    /**
     * Make http DELETE request
     * @param url 
     * @param params 
     * @param useBaseHttpUrl 
     * @returns 
     */
    static async delete(url: string, params: Record<string, any>, useBaseHttpUrl: boolean = true) {

        const options = {
            url: (useBaseHttpUrl ? this.BASEHTTPURL : '') +  url,
            method: 'DELETE',
            data: params,
          };
          
        return await axios(options);
    }

    /**
     * Make http POST request
     * @param url 
     * @param params 
     * @param useBaseHttpUrl 
     * @returns 
     */
    static async post(url: string, params: Record<string, any> | FormData, useBaseHttpUrl: boolean = true, headers?: Record<string, any>) {

        const options = {
            url: (useBaseHttpUrl ? this.BASEHTTPURL : '') +  url,
            method: 'POST',
            data: params,
            headers,
          };
          
        return await axios(options);
    }

}