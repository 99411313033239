import React from "react";
import { PageLoader } from "../../components/pageLoader";
import { Button, Col, Form, Input, InputNumber, Row, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ATMCard, ATMCardService } from "../../services/ATMCardService";

const { Title } = Typography;

export class CardElement extends React.Component<any,any> {

    state : {
        item: ATMCard | undefined,
        itemId: number,
        loaded: boolean,
    } = {
        item: undefined,
        itemId: -1, // Default 0, is used only when needed
        loaded: false,
    };

    /**
     * Check param data on component load
     */
    componentDidMount() {

        // Read item id to know if is an update or an insert
        const itemId = window.location.href.split('/')[6]


        // if passed in GET load it from state
        if(itemId) {
            this.loadItem(parseInt(itemId));
        } else {
            // Remove Loader
            this.setState({
                loaded: true
            });
        }

    }

    /**
     * Load item from Server
     */
    async loadItem(id: number) {

        try {
            const response = await ATMCardService.item(id);

            // Save response
            this.setState({
                item: response,
                loaded: true,
                itemId: id, // Save also item ID
            });

        } catch(e) {
            
            // Return to list
            window.location.href = `/home/cards`

        }
        
    }

    /**
     * Is an item upadte? Or is an insert?
     * @returns 
     */
    isUpdate(): boolean {
        return this.state.item !== undefined;
    }

    /**
     * On form submit when valied
     * @param values
     */
    async onFinish(values: any) {
        
        /**
         * Show loader
         */
        this.setState({
            loaded: true,
        });

        try {

            if( this.isUpdate() ) {
                await ATMCardService.update({
                    id: this.state.itemId,
                    ...values
                });
            } else {
                await ATMCardService.create({
                    ...values
                });
            }

            // Return to list
            window.location.href = `/home/cards`;

        } catch(e) {
            alert('Server Error');

        } finally {

        }
    }

    /**
     * Render data
     * @returns 
     */
    render() {

        /**
         * if not loaded -> return empty
         */
        if(!this.state.loaded) {
            return <PageLoader />;
        }

        return <>
            <Row justify='space-between' align='middle'>
                <Col>
                    <Title>{this.isUpdate() ? 'Modifica' : 'Aggiungi'} Tessera</Title>
                </Col>        
            </Row>


            <Form
                name="basic"
                onFinish={(data: any) => this.onFinish(data)}
                autoComplete="off"
                initialValues={{...this.state.item}}
            >
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label="Zone"
                                name="zones"
                                rules={[{ required: true, message: 'Campo obbligatorio' }]}
                                >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Prezzo"
                                name="price"
                                rules={[{ required: true, message: 'Campo obbligatorio' }]}
                                >
                                <InputNumber />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            <Form.Item>
                                <Space>
                                    <Link to='/home/cards'>
                                        <Button type='primary' danger icon={<ArrowLeftOutlined />}>Indietro</Button>
                                    </Link>
                                    <Button type='primary' htmlType="submit">Salva</Button>
                                </Space>
                                
                            </Form.Item>
                        </Col>
                    </Row>
            </Form>
            
        </>;
    }

}