import dayjs from "dayjs";

export const convertDateToServerFormat = (date: string) => {
    const newDate = new Date(date);
    return newDate.getFullYear() + '-' + (newDate.getMonth()+1).toString().padStart(2, '0') + '-' + newDate.getDate().toString().padStart(2, '0');
}

export const convertDateToClientFormat = (date: string) => {
    return dayjs(new Date(date));
}
