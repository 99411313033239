import { HttpService } from "./HttpService";
import { User } from "./UserService";

export interface CafListResponse {
    address: string;
    addressNumber: number;
    city: string;
    id: number;
    name: string;
    postalCode: string;
    province: string;
    users: User[];
   
}

export interface CafItemResponse {
    address: string;
    addressNumber: number;
    city: string;
    id: number;
    name: string;
    postalCode: string;
    province: string;
    cardMargin: number;

     // Used for relations
     users_id: number[];
     users: User[];
}

export class CafService {


    /**
     * Get CAF List from server
     * @returns CafListResponse[] Response
     */
    static async list(): Promise<CafListResponse[]> {
        const response = await HttpService.get('/caf', {});
        return response?.data;
    }

    /**
     * Get CAF Item from server
     * @returns CafItemResponse Response
     */
    static async item(id: number): Promise<CafItemResponse> {
        const response = await HttpService.get(`/caf/item/${id}`, {});

        response.data.users_id = response.data.users.map((el: User) => el.id);
        return response?.data;
    }

    /**
     * Delet CAF from server
     * @returns boolean
     */
    static async delete(id: number) {
        const response = await HttpService.delete('/caf/delete', {id});
        return response?.data;
    }

    /**
     * Update on Server
     * @returns boolean
     */
    static async update(payload: CafItemResponse) {
        const response = await HttpService.post(`/caf/update`, payload);
        return response?.data;
    }


    /**
     * Create on Server
     * @returns boolean
     */
    static async create(payload: CafItemResponse) {
        const response = await HttpService.post(`/caf/create`, payload);
        return response?.data;
    }


     /**
     * Retrieve my caf data
     * @returns boolean
     */
     static async myCaf(): Promise<CafItemResponse> {
        const response = await HttpService.get(`/caf/my-caf`, {});
        return response?.data;
    }
}