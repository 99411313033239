import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Login } from './pages/login';
import { Dashboard } from './pages/dashboard';
import { Home } from './pages/home';
import { CAFList, CafElement } from './pages/caf';
import { ClientElement, ClientsList } from './pages/clients';
import { CardElement, CardsList } from './pages/cards';
import { Totals } from './pages/totals';
import { HttpService } from './services/HttpService';
import { RequestElement, RequestList } from './pages/requests';
import { UserElement, UsersList } from './pages/users';

class App extends React.Component {

	constructor(props: any) {
		// Call parent constructor
		super(props);

		// Set Axios Intrceptor
		HttpService.setAxiosInterceptor();
	}

	render () {
		return (
			<>
				<Routes>
					<Route path="/" element={<Login />} />

					<Route path="/home" element={<Home />}>
						<Route path="dashboard" element={<Dashboard />} />

						<Route path="caf" element={<CAFList />} />
						<Route path="caf/item/:id?" element={<CafElement />} />

						<Route path="clients" element={<ClientsList />} />
						<Route path="clients/item/:id?" element={<ClientElement />} />

						<Route path="cards" element={<CardsList />} />
						<Route path="cards/item/:id?" element={<CardElement />} />

						<Route path="requests" element={<RequestList />} />
						<Route path="requests/item/:id?" element={<RequestElement />} />

						<Route path="users" element={<UsersList />} />
						<Route path="users/item/:id?" element={<UserElement />} />

						<Route path="totals" element={<Totals />} />
					</Route>

				</Routes>
			</>
		);
	}
		
}

export default App;

