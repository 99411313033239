import { Col, Row, Spin } from "antd";
import React from "react";
import './index.scss';

export class ListLoader extends React.Component {

    render() {
        return <Row className='list-loader' justify={'center'}>
                <Col>
                    <Spin size="large" />
                </Col>
            </Row>;
    }
}