import React from "react";
import { PageLoader } from "../../components/pageLoader";
import { Button, Col, DatePicker, Divider, Form, Input, Row, Select, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { CafClient, CafClientService } from "../../services/CafClientService";
import { VALIDATIONS } from "../../utils/validations";
import { convertDateToServerFormat } from "../../utils/utils";

const { Title } = Typography;

export class ClientElement extends React.Component<any,any> {

    state : {
        item: CafClient | undefined,
        itemId: number,
        loaded: boolean,
    } = {
        item: undefined,
        itemId: -1, // Default 0, is used only when needed
        loaded: false,
    };

    /**
     * Check param data on component load
     */
    componentDidMount() {

        // Read item id to know if is an update or an insert
        const itemId = window.location.href.split('/')[6]

        // if passed in GET load it from state
        if(itemId) {
            this.loadItem(parseInt(itemId));
        } else {
            // Remove Loader
            this.setState({
                loaded: true
            });
        }

    }

    /**
     * Load item from Server
     */
    async loadItem(id: number) {

        try {
            const response = await CafClientService.item(id);
            response.billing = response?.addresses.find(el => el.type === 'billing');
            response.delivery = response?.addresses.find(el => el.type === 'delivery');


            // Save response
            this.setState({
                item: response,
                loaded: true,
                itemId: id, // Save also item ID
            });

        } catch(e) {
            
            // Return to list
            window.location.href = `/home/clients`

        }
        
    }

    /**
     * Is an item upadte? Or is an insert?
     * @returns 
     */
    isUpdate(): boolean {
        return this.state.item !== undefined;
    }

    /**
     * On form submit when valied
     * @param values
     */
    async onFinish(values: any) {
        
        /**
         * Show loader
         */
        this.setState({
            loaded: true,
        });

        if( values['birthDate'] ) {
            values['birthDate'] = convertDateToServerFormat(values['birthDate']);
        }

        try {

            if( this.isUpdate() ) {
                await CafClientService.update({
                    id: this.state.itemId,
                    ...values
                });
            } else {
                await CafClientService.create({
                    ...values
                });
            }

            // Return to list
            window.location.href = `/home/clients`;

        } catch(e) {
            alert('Server Error');

        } finally {

        }
    }

    /**
     * Render data
     * @returns 
     */
    render() {

        /**
         * if not loaded -> return empty
         */
        if(!this.state.loaded) {
            return <PageLoader />;
        }

        return <>
            <Row justify='space-between' align='middle'>
                <Col>
                    <Title>{this.isUpdate() ? 'Modifica' : 'Aggiungi'} Cliente</Title>
                </Col>        
            </Row>


            <Form
                name="basic"
                onFinish={(data: any) => this.onFinish(data)}
                autoComplete="off"
                initialValues={{...this.state.item}}
            >
                    <BaseForm />
                    <BillingForm />
                    {/* <DeliveryForm /> */}
                    
                    <Row>
                        <Col span={8}>
                            <Form.Item>
                                <Space>
                                    <Link to='/home/clients'>
                                        <Button type='primary' danger icon={<ArrowLeftOutlined />}>Indietro</Button>
                                    </Link>
                                    <Button type='primary' htmlType="submit">Salva</Button>
                                </Space>
                                
                            </Form.Item>
                        </Col>
                    </Row>
            </Form>
            
        </>;
    }

}


class BaseForm extends React.Component {
    render() {
        return <>
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item
                        label="Nome"
                        name="name"
                        rules={[{ required: true, message: 'Campo obbligatorio' }]}
                        >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Cognome"
                        name="lastName"
                        rules={[{ required: true, message: 'Campo obbligatorio' }]}
                        >
                        <Input />
                    </Form.Item>
                </Col>
                
            </Row>
            <Row gutter={16}>

                <Col span={8}>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={VALIDATIONS.EMAIL}
                        >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Telefono"
                        name="phone"
                        rules={VALIDATIONS.PHONE}
                        >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Cittadinanza"
                        name="citizenship"
                        rules={[{ message: 'Campo obbligatorio' }]}
                        >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Data di Nascita"
                        name="birthDate"
                        rules={[
                            // { message: 'Campo obbligatorio' }
                        ]}
                        >
                        <DatePicker />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Luogo Nascita"
                        name="birthCountry"
                        rules={[{ message: 'Campo obbligatorio' }]}
                        >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Sesso"
                        name="sex"
                        rules={[{ message: 'Campo obbligatorio' }]}
                        >
                        <Select
                            defaultValue=""
                            options={[
                                { value: 'M', label: 'Maschio' },
                                { value: 'F', label: 'Femmina' },
                            ]}
                            />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Codice fiscale"
                        name="phiscalCode"
                        rules={VALIDATIONS.PHISCAL_CODE}
                        >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Tipologia documento"
                        name="documentType"
                        rules={[{ message: 'Campo obbligatorio' }]}
                        >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Numero documento"
                        name="documentNumber"
                        rules={[{ message: 'Campo obbligatorio' }]}
                        >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </>;
    }
}

class BillingForm extends React.Component {

    render() {
        return <>
            <Divider dashed children={<>Dati di fatturazione</>} orientation="left" />

            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item
                        label="Indirizzo"
                        name={['billing', 'address']}
                        rules={[{ message: 'Campo obbligatorio' }]}
                        >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Numero"
                        name={['billing', 'addressNumber']}
                        rules={[{ message: 'Campo obbligatorio' }]}
                        >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Provincia"
                        name={['billing', 'province']}
                        rules={VALIDATIONS.PROVINCE}
                        >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Città"
                        name={['billing', 'city']}
                        rules={[{ message: 'Campo obbligatorio' }]}
                        >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="CAP"
                        name={['billing', 'postalCode']}
                        rules={VALIDATIONS.CAP}
                        >
                        <Input />
                    </Form.Item>
                </Col>            
            </Row>
        </>;
    }
}

class DeliveryForm extends React.Component {

    render() {
        return <>
            <Divider dashed children={<>Dati di consegna</>} orientation="left" />

            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item
                        label="Indirizzo"
                        name={['delivery', 'address']}
                        rules={[{ required: true, message: 'Campo obbligatorio' }]}
                        >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Numero"
                        name={['delivery', 'addressNumber']}
                        rules={[{ required: true, message: 'Campo obbligatorio' }]}
                        >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Provincia"
                        name={['delivery', 'province']}
                        rules={VALIDATIONS.PROVINCE}
                        >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Città"
                        name={['delivery', 'city']}
                        rules={[{ required: true, message: 'Campo obbligatorio' }]}
                        >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="CAP"
                        name={['delivery', 'postalCode']}
                        rules={VALIDATIONS.CAP}
                        >
                        <Input />
                    </Form.Item>
                </Col>            
            </Row>
        </>;
    }
}
