import { Route } from "../types/route";
import { HttpService } from "./HttpService";

export interface User {
    id: number
    name: string
    lastName: string
    email: string;
    password: string;
    group_id: string;
    groups: Group[];
}

export interface Group {
    id: number;
    name: string;
    type: string;
}

export interface AuthResponse {
    id: number;
    access_token: string,
    token_type: string;
    routes: Route[];
}


export class UserService {

    static accessToken: string;
    static routes: Route[];

    static setAccessToken(accessToken: string) {
        this.accessToken = accessToken;
        localStorage.setItem('accessToken', accessToken);
    }

    static getAccessToken() {
        return localStorage.getItem('accessToken');
    }

    static setId(id: number) {
        localStorage.setItem('id', id.toString());
    }

    static getId() {
        return localStorage.getItem('id');
    }

    static clearUserTokens() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('routes');
        localStorage.removeItem('id');
    }

    static setRoutes(routes: Route[]) {
        this.routes = routes;
        localStorage.setItem('routes', JSON.stringify(routes));
    }

    static getRoutes() {
        const routes = localStorage.getItem('routes') || [];
        try {
            return JSON.parse(routes as any);
        } catch(e) {
            return [];
        }
    }
    
    static async authenticate(email: string, password: string) {
        const response = await HttpService.post(`/login`, {email, password});
        return response?.data;
    }

    /**
     * Get List from server
     * @returns User[] Response
     */
    static async list(): Promise<User[]> {
        const response = await HttpService.get('/users', {});
        return response?.data;
    }

    /**
     * Get single Item from server
     * @returns User Response
     */
    static async item(id: number): Promise<User> {
        const response = await HttpService.get(`/users/item/${id}`, {});

        // Save group id in main object
        response.data.group_id = response?.data?.groups?.[0]?.id;

        return response?.data;
    }

    /**
     * Delet item from server
     * @returns boolean
     */
    static async delete(id: number) {
        const response = await HttpService.delete('/users/delete', {id});
        return response?.data;
    }

    /**
     * Update item on Server
     * @returns boolean
     */
    static async update(payload: User) {
        const response = await HttpService.post(`/users/update`, payload);
        return response?.data;
    }


    /**
     * Create on Server
     * @returns boolean
     */
    static async create(payload: User) {
        const response = await HttpService.post(`/users/create`, payload);
        return response?.data;
    }
}