import { HttpService } from "./HttpService";

export interface ATMCard {
    zones: string;
    id: number;
    price: number;
}


export class ATMCardService {


    /**
     * Get List from server
     * @returns ATMCard[] Response
     */
    static async list(): Promise<ATMCard[]> {
        const response = await HttpService.get('/atm/cards', {});
        return response?.data;
    }

    /**
     * Get Item from server
     * @returns ATMCard Response
     */
    static async item(id: number): Promise<ATMCard[]> {
        const response = await HttpService.get(`/atm/cards/item/${id}`, {});
        return response?.data;
    }

    /**
     * Delet item from server
     * @returns boolean
     */
    static async delete(id: number) {
        const response = await HttpService.delete('/atm/cards/delete', {id});
        return response?.data;
    }

    /**
     * Update item on Server
     * @returns boolean
     */
    static async update(payload: ATMCard) {
        const response = await HttpService.post(`/atm/cards/update`, payload);
        return response?.data;
    }


    /**
     * Create on Server
     * @returns boolean
     */
    static async create(payload: ATMCard) {
        const response = await HttpService.post(`/atm/cards/create`, payload);
        return response?.data;
    }
}