import { Alert, Button, Col, DatePicker, Divider, Empty, Modal, Row, Select, Space, Table, Tooltip, Typography } from "antd";
import React from "react";
import { TotalsRecord, TotalsService } from "../../services/TotalsService";
import { EyeOutlined } from "@ant-design/icons";
import { ListLoader } from "../../components/listLoader";
import './index.scss';
import { CafListResponse, CafService } from "../../services/CafService";
import { convertDateToServerFormat } from "../../utils/utils";
import { ATMRequestService, CardCost } from "../../services/ATMRequestService";

const { Title } = Typography;
const { RangePicker } = DatePicker;

export class Totals extends React.Component {

     state : {
        list: TotalsRecord[],
        cafList: CafListResponse[],
        loaded: boolean,
        modalOpen: boolean,
        detailsRecord: TotalsRecord | undefined,
        cafId: string,
        fromDate: string,
        toDate: string,
        cardCost: undefined | CardCost,
    } = {
        list: [],
        cafList: [],
        loaded: false,
        modalOpen: false,
        detailsRecord: undefined,
        cafId: '',
        fromDate: '',
        toDate: '',
        cardCost: undefined,
    };

    /**
     * On component mount event
     */
    componentDidMount() {
    
        // Call Server API
        this.getList({});

         // Get filters lists
        this.getFilters();
    }

    /**
     * Get filters lists
     */
    async getFilters() {
        try {

            // Items list
            const cafList = await CafService.list();

            // Save response
            this.setState({
                cafList,
            });

        } catch(e) {
            
            // Error
        }
    }

    /**
     * Get List from Server
     */
    async getList({cafId, fromDate, toDate}: {cafId?: string, fromDate?: string, toDate?: string}) {
         // Filter object
         const filters = {
            cafId: cafId !== undefined ? cafId : this.state.cafId,
            fromDate: fromDate !== undefined ? fromDate : this.state.fromDate,
            toDate: toDate !== undefined ? toDate : this.state.toDate,
        }

        const response = await TotalsService.list(filters);
        this.setState({
            list: response,
            loaded: true,
        });
    }

    getTotal() {
        return this.state.list.reduce((acc, curr) => acc + ATMRequestService.cardCost(curr.card.price, curr.client.caf.cardMargin, curr.fromDate, curr.expireDate).total, 0);
    }

    /**
     * Return dataTable columns
     */
    getColumns(openModal: (data: TotalsRecord) => void) {
        return [
            // {
            //     title: 'ID',
            //     dataIndex: 'id',
            //     key: 'id',
            // },
            {
                title: 'Cliente',
                key: 'client',
                render: (element: TotalsRecord) => `${element?.client?.lastName} ${element?.client?.name}`
            },
            {
                title: 'Caf',
                key: 'caf',
                render: (element: TotalsRecord) => `${element?.client?.caf?.name}`
            },
            {
                title: 'Tessera',
                key: 'card',
                render: (element: TotalsRecord) => `${element?.card?.zones}€`
            },
            {
                title: 'Nuova tessera',
                key: 'newCard',
                render: (element: TotalsRecord) => <>
                    {ATMRequestService.cardCost(element.card.price, element.client.caf.cardMargin, element.fromDate, element.expireDate).requireNewCard ? 'SI' : 'NO'}
                </>
            },
            {
                title: 'Prezzo tessera',
                key: 'price',
                render: (element: TotalsRecord) => `${element?.card?.price}€`
            },
            {
                title: 'Margine',
                key: 'cardMargin',
                render: (element: TotalsRecord) => `${element?.client?.caf?.cardMargin}€`
            },
            {
                title: 'Totale',
                key: 'totalPrice',
                render: (element: TotalsRecord) => <b>{ATMRequestService.cardCost(element.card.price, element.client.caf.cardMargin, element.fromDate, element.expireDate).total}€</b>
            },
            {
                title: 'Data inizio abbonamento',
                key: 'requestDate',
                render: (element: TotalsRecord) => `${element?.fromDate}`
            },
            {
                title: '#',
                key: 'other',
                render: (element: TotalsRecord) => {
                    return <>
                        <Space wrap>
                            <Tooltip title="Mostra dettagli">
                                <Button type="primary" shape="circle" icon={<EyeOutlined />} onClick={() => openModal(element)} />
                            </Tooltip>
                        </Space>
                    </>
                }
            }
          ];
    }

    render() {

        /**
         * if not loaded -> return empty
         */
        if(!this.state.loaded) {
            return <ListLoader />;
        }
        
        return <>

            <Row justify='space-between' align='middle'>
                <Col>
                    <Title>Totali</Title>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={6}>
                    CAF <br/>
                    <Select
                        defaultValue={'-'}
                        className={'input-filter'}
                        options={
                            [
                                {
                                    label: 'Tutti',
                                    value: 0,
                                }
                            ].concat(
                                this.state.cafList.map(el => ({label: `${el.name}` , value: el.id}))
                            )
                            
                        }
                        onChange={(cafId) => {
                            // Set class state
                            this.setState({cafId});

                            // Send request
                            this.getList({cafId});
                        }}
                    />
                </Col>
                <Col span={6}>
                    Range <br/>
                    
                    
                    <RangePicker 
                        className={'input-filter'}
                        onChange={(values) => {

                            const fromDate = values ? convertDateToServerFormat(values?.[0]?.toISOString() || '') : '';
                            const toDate = values ? convertDateToServerFormat(values?.[1]?.toISOString() || '') : '';
                            
                            // Set class state
                            this.setState({fromDate, toDate});

                            // Send request
                            this.getList({fromDate, toDate});
                        }}
                    />
                </Col>
            </Row>

            <br /><br />

            {
                this.state.list.length > 0 && 
                <>
                    <Table 
                        columns={this.getColumns((record) => {

                            // Save cost for this record
                            this.setState({
                                cardCost: ATMRequestService.cardCost(record.card.price, record.client.caf.cardMargin, record.fromDate, record.expireDate),
                                detailsRecord: record, modalOpen: true
                            });
                        })} 
                        dataSource={this.state.list} 
                        pagination={false} 
                        />
                    
                    <Row justify={'end'} className={'totals'}>
                        <Col span={8}>
                                <Alert
                                message="Totale"
                                description={`${this.getTotal()}€`}
                                type="info"
                                showIcon
                                />
                            
                        </Col>
                    </Row>
                </>
                
                
            }

            {
                this.state.list.length === 0 &&
                <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{ height: 60 }}
                    description={
                    <span>
                        Non sono presenti richieste per la selezione effettuata
                    </span>
                    }
                >
                    
                </Empty>
            }

                
            <Modal 
                title="Dettagli richiesta" 
                width={1200} 
                open={this.state.modalOpen} 
                onCancel={() => this.setState({modalOpen: false})}
                
                footer={[
                    <Button 
                        key="1" 
                        type="primary"
                        onClick={() => this.setState({modalOpen: false})}>Chiudi</Button>
                ]}>
                {
                    this.state.detailsRecord &&
                    <>
                        <Divider orientation={'left'} orientationMargin={10}>Richiesta</Divider>
                        <Row gutter={[16,16]}>
                            <Col span={8}>
                                <b>A partire da</b> <br/>
                                {this.state.detailsRecord?.fromDate}
                            </Col>
                            <Col span={8}>
                                <b>Data scadenza</b> <br/>
                                {this.state.detailsRecord?.expireDate || '-'}
                            </Col>
                            <Col span={8}>
                                <b>Zone</b> <br/>
                                {this.state.detailsRecord?.card?.zones}
                            </Col>
                            <Col span={6}>
                                <b>Prezzo tessera</b> <br/>
                                {this.state.cardCost?.cardCost}€
                            </Col>
                            <Col span={6}>
                                <b>Margine CAF</b> <br/>
                                {this.state.cardCost?.margin}€
                            </Col>
                            <Col span={6}>
                                <b>Nuova tessera</b> <br/>
                                {this.state.cardCost?.requireNewCard ? 'SI' : 'NO'}
                            </Col>
                            <Col span={6}>
                                <b>Totale</b> <br/>
                                <b>{this.state.cardCost?.total}€</b>
                            </Col>
                            {/* <Col span={12}>
                                <b>Immagine</b> <br/>
                                <a href={`http://localhost${this.state.detailsRecord?.photoSrc}`} target='_blank'>
                                    <img src={`http://localhost${this.state.detailsRecord?.photoSrc}`} className='uploaded-photo' />
                                </a>
                                
                            </Col> */}
                        </Row>

                        <Divider orientation={'left'} orientationMargin={10}>Cliente</Divider>
                        <Row gutter={[16,16]}>
                            <Col span={8}>
                                <b>Nome</b> <br/>
                                {this.state.detailsRecord?.client?.name}
                            </Col>
                            <Col span={8}>
                                <b>Cognome</b> <br/>
                                {this.state.detailsRecord?.client?.lastName}
                            </Col>
                            <Col span={8}>
                                <b>Email</b> <br/>
                                {this.state.detailsRecord?.client?.email}
                            </Col>
                            <Col span={8}>
                                <b>Telefono</b> <br/>
                                {this.state.detailsRecord?.client?.phone}
                            </Col>
                            <Col span={8}>
                                <b>Cittadinanza</b> <br/>
                                {this.state.detailsRecord?.client?.citizenship}
                            </Col>
                            <Col span={8}>
                                <b>Data di nascita</b> <br/>
                                {this.state.detailsRecord?.client?.birthDate}
                            </Col>
                            <Col span={8}>
                                <b>Luogo di nascita</b> <br/>
                                {this.state.detailsRecord?.client?.birthCountry}
                            </Col>
                            <Col span={8}>
                                <b>Sesso</b> <br/>
                                {this.state.detailsRecord?.client?.sex}
                            </Col>
                            <Col span={8}>
                                <b>Codice fiscale</b> <br/>
                                {this.state.detailsRecord?.client?.phiscalCode}
                            </Col>
                            <Col span={8}>
                                <b>Tipologia documento</b> <br/>
                                {this.state.detailsRecord?.client?.documentType}
                            </Col>
                            <Col span={8}>
                                <b>Numero documento</b> <br/>
                                {this.state.detailsRecord?.client?.documentNumber}
                            </Col>
                        </Row>

                        <Divider orientation={'left'} orientationMargin={10}>Caf richiedente</Divider>
                        <Row gutter={[16,16]}>
                            <Col span={8}>
                                <b>Nome</b> <br/>
                                {this.state.detailsRecord?.client?.caf?.name}
                            </Col>
                            <Col span={8}>
                                <b>Effettuata da</b> <br/>
                                <b>{this.state.detailsRecord?.user?.lastName} {this.state.detailsRecord?.user?.name}</b>
                            </Col>
                            <Col span={8}>
                                <b>Indirizzo</b> <br/>
                                {this.state.detailsRecord?.client?.caf?.address} {this.state.detailsRecord?.client?.caf?.addressNumber}
                            </Col>
                            <Col span={8}>
                                <b>Provincia</b> <br/>
                                {this.state.detailsRecord?.client?.caf?.province}
                            </Col>
                            <Col span={8}>
                                <b>Città</b> <br/>
                                {this.state.detailsRecord?.client?.caf?.city}
                            </Col>
                            <Col span={8}>
                                <b>Codice postale</b> <br/>
                                {this.state.detailsRecord?.client?.caf?.postalCode}
                            </Col>
                        </Row>
                    </>
                }
            </Modal>
        
            
            
        </>;
    }
}