import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Row, Typography } from "antd";
import React from "react";
import './index.scss';
import { UserService } from "../../services/UserService";

const { Text } = Typography;


const { Title } = Typography;

export class Login extends React.Component {

    state: {
        errorMessage: string
    } = {
        errorMessage: ''
    };

    async onFinish(values: {email: string, password: string}) {
        // Reset message error
        this.setState({errorMessage: ''});

        try {
            const response = await UserService.authenticate(values.email, values.password);

            // Save acces token and available routes
            UserService.setAccessToken(response.access_token);
            UserService.setRoutes(response.routes);
            UserService.setId(response.id);

            // Redirect to to home
            window.location.href = '/home';

        } catch(e) {
            const error: any = e;
            if(error?.code === 'ERR_BAD_REQUEST') {
                this.setState({errorMessage: 'Credenziali errate'})
            } else {
                this.setState({errorMessage: 'Credenziali errate'})
            }
        }
    }

    render() {
        return <>
            <Row justify={'center'}>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={(values) => this.onFinish(values)}
                    >
                    
                    <Title>CAF - User login</Title>
                    <Form.Item
                        name="email"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your Username!',
                        },
                        ]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your Password!',
                        },
                        ]}
                    >
                        <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                        />
                    </Form.Item>
                    <Form.Item>
                        {
                            this.state.errorMessage &&
                            <>
                                <Text type="danger">{this.state.errorMessage}</Text> <br /><br />
                            </>
                            
                        }
                        
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Accedi
                        </Button>
                    </Form.Item>
                </Form>
            </Row>
        </>;
    }
}