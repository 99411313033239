import React from "react";
import { PageLoader } from "../../components/pageLoader";
import { Button, Col, Form, Input, Row, Select, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { User, UserService } from "../../services/UserService";
import { UserGroup, UserGroupService } from "../../services/UserGroupService";

const { Title } = Typography;

export class UserElement extends React.Component<any,any> {

    state : {
        item: User | undefined,
        itemId: number,
        loaded: boolean,
        userGroups: UserGroup[],
        userId: number,
    } = {
        item: undefined,
        itemId: -1, // Default 0, is used only when needed
        loaded: false,
        userGroups: [],
        userId: parseInt(UserService.getId()!),
    };

    /**
     * Check param data on component load
     */
    async componentDidMount() {

        // Read item id to know if is an update or an insert
        const itemId = window.location.href.split('/')[6]

        // Load needed lists
        await this.loadLists();


        // if passed in GET load it from state
        if(itemId) {
            this.loadItem(parseInt(itemId));
        } else {
            // Remove Loader
            this.setState({
                loaded: true
            });
        }

    }

    /**
     * Load groups
     */
    async loadLists() {
        try {

             // Items list
            const userGroups = await UserGroupService.list();

            // Save response
            this.setState({
                userGroups,
            });

            } catch(e) {
                
                // Return to list
                window.location.href = `/home/users`
            }
    }

    /**
     * Load item from Server
     */
    async loadItem(id: number) {

        try {
            const response = await UserService.item(id);

            // Save response
            this.setState({
                item: response,
                loaded: true,
                itemId: id, // Save also item ID
            });

        } catch(e) {
            
            // Return to list
            window.location.href = `/home/users`

        }
        
    }

    /**
     * Is an item upadte? Or is an insert?
     * @returns 
     */
    isUpdate(): boolean {
        return this.state.item !== undefined;
    }

    /**
     * On form submit when valied
     * @param values
     */
    async onFinish(values: any) {
        
        /**
         * Show loader
         */
        this.setState({
            loaded: true,
        });

        // Unset password field if not filled
        if(!values.password) {
            try {
                delete values.password;
            } catch(e) {

            }
            
        }

        try {

            if( this.isUpdate() ) {
                await UserService.update({
                    id: this.state.itemId,
                    ...values
                });
            } else {
                await UserService.create({
                    ...values
                });
            }

            // Return to list
            window.location.href = `/home/users`;

        } catch(e) {
            console.log('error', e);
            alert('Server Error');

        } finally {

        }
    }

    /**
     * Render data
     * @returns 
     */
    render() {

        /**
         * if not loaded -> return empty
         */
        if(!this.state.loaded) {
            return <PageLoader />;
        }

        return <>
            <Row justify='space-between' align='middle'>
                <Col>
                    <Title>{this.isUpdate() ? 'Modifica' : 'Aggiungi'} Utente</Title>
                </Col>        
            </Row>


            <Form
                name="basic"
                onFinish={(data: any) => this.onFinish(data)}
                autoComplete="off"
                initialValues={{...this.state.item}}
            >
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label="Nome"
                                name="name"
                                rules={[{ required: true, message: 'Campo obbligatorio' }]}
                                >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="Cognome"
                                name="lastName"
                                rules={[{ required: true, message: 'Campo obbligatorio' }]}
                                >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Gruppo"
                                name="group_id"
                                rules={[{ required: true, message: 'Campo obbligatorio' }]}
                                >
                                <Select
                                    disabled={this.state.userId === this.state.item?.id}
                                    options={this.state.userGroups.map(el => ({label: `${el.name}` , value: el.id}))}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{ required: true, message: 'Campo obbligatorio' }]}
                                >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    
                                    { min: 8, message: 'Lunghezza minima 8 caratteri' },
                                ]}
                                >
                                <Input type="password" />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row>
                        <Col span={8}>
                            <Form.Item>
                                <Space>
                                    <Link to='/home/users'>
                                        <Button type='primary' danger icon={<ArrowLeftOutlined />}>Indietro</Button>
                                    </Link>
                                    <Button type='primary' htmlType="submit">Salva</Button>
                                </Space>
                                
                            </Form.Item>
                        </Col>
                    </Row>
            </Form>
            
        </>;
    }

}