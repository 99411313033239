import React from "react";
import { PageLoader } from "../../components/pageLoader";
import { Alert, Button, Checkbox, Col, Form, Row, Select, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ATMCard, ATMCardService } from "../../services/ATMCardService";
import { ATMRequest, ATMRequestService } from "../../services/ATMRequestService";
import { CafClient, CafClientService } from "../../services/CafClientService";
import { FileUploader } from "../../components/fileUploader";
import { DatePicker } from 'antd';
import { convertDateToServerFormat } from "../../utils/utils";
import moment from "moment";
import './requests.scss';
import { CafItemResponse, CafService } from "../../services/CafService";

const { Title } = Typography;

export class RequestElement extends React.Component<any,any> {

    state : {
        item: ATMRequest | undefined,
        cafClients: CafClient[],
        cardsList: ATMCard[],
        itemId: number,
        loaded: boolean,
        cardPhotoUrl: string | undefined,
        cardStartLimit: string | undefined,
        fromDate: any | undefined,
        selectedZone: number | null,
        expireDate: any | null,
        myCaf: CafItemResponse | undefined,
        newCard: boolean,
    } = {
        item: undefined,
        cafClients: [],
        cardsList: [],
        itemId: -1, // Default 0, is used only when needed
        loaded: false,
        cardPhotoUrl: undefined,
        cardStartLimit: undefined,
        fromDate: undefined,
        selectedZone: null,
        expireDate: null,
        myCaf: undefined,
        newCard: false,
    };

    /**
     * Check param data on component load
     */
    async componentDidMount() {

        // Read item id to know if is an update or an insert
        const itemId = window.location.href.split('/')[6];

       // Load clients and atm cards type
       await this.loadLists();

       // Calculate start date
       this.calculateStartDate();

        // if passed in GET load it from state
        if(itemId) {
            this.loadItem(parseInt(itemId));

            
        } else {
            // Remove Loader
            this.setState({
                loaded: true
            });
        }

    }

    /**
     * Start date from which request can be done
     */
    calculateStartDate() {

        // from date for request
        const fromDate = moment();

        // get month day
        const monthDay = fromDate.date();

        // if < 5 -> can start from next month
        if( monthDay < 5 ) {
            fromDate.add(1, 'month');
        } 
        // add two monthes
        else {
            fromDate.add(2, 'months');
        }

        const cardStartLimit = fromDate.startOf('month').format('YYYY-MM-DD');
        
        // Set from which day request can be done
        this.setState({
            cardStartLimit
        });
        
    }

    /**
     * Load clients and atm cards type
     */
    async loadLists() {
        try {

             // Items list
            const cafClients = await CafClientService.list();
            const cardsList = await ATMCardService.list();
            const myCaf = await CafService.myCaf();

            // Save response
            this.setState({
                cafClients,
                cardsList,
                myCaf,

            });

            } catch(e) {
                
                // Return to list
                window.location.href = `/home/requests`
            }
    }

    /**
     * Load item from Server
     */
    async loadItem(id: number) {

        try {
            const response = await ATMRequestService.item(id);

            // Save response
            this.setState({
                fromDate: response.fromDate,
                expireDate: response?.expireDate,
                item: response,
                loaded: true,
                itemId: id, // Save also item ID
                selectedZone: response.card_id,
                newCard: response?.expireDate ? false : true,
            });

        } catch(e) {
            
            // Return to list
            window.location.href = `/home/requests`

        }
        
    }

    /**
     * Is an item upadte? Or is an insert?
     * @returns 
     */
    isUpdate(): boolean {
        return this.state.item !== undefined;
    }

    /**
     * On form submit when valied
     * @param values
     */
    async onFinish(values: any) {

        /**
         * Show loader
         */
        this.setState({
            loaded: true,
        });

        // Set photo url
        const newValues = JSON.parse( JSON.stringify(values) );
        if(this.state.cardPhotoUrl) {
            newValues['photoSrc'] = this.state.cardPhotoUrl;
        }

        newValues['fromDate'] = convertDateToServerFormat(newValues['fromDate']);
        newValues['expireDate'] = this.state.newCard ? null : convertDateToServerFormat(newValues['expireDate']);

        try {

            if( this.isUpdate() ) {
                await ATMRequestService.update({
                    id: this.state.itemId,
                    ...newValues
                });
            } else {
                await ATMRequestService.create({
                    ...newValues
                });
            }

            // Return to list
            window.location.href = `/home/requests`;

        } catch(e) {
            alert('Server Error');

        } finally {

        }
    }

    getExpireDate() {
        return this.state.newCard ? this.state.fromDate : this.state.expireDate;
    }


    getCardValue() {

        /**
         * Extract zones name
         */
        const card = this.state.cardsList.find(e => e.id == this.state.selectedZone);

        const expireDate = this.getExpireDate();

        if( !this.state.fromDate || !expireDate ) {
            return;
        }


        const cost = ATMRequestService.cardCost(
           card?.price || 0, 
           this.state.myCaf?.cardMargin || 0, 
           this.state.fromDate.format('YYYY-MM-DD'), 
           expireDate.format('YYYY-MM-DD')
        );

        
        return <>
            <table className="totals-table">
                <tbody>
                    <tr>
                        <td>TESSERA {card?.zones}</td>
                        <td>{cost.cardCost}€</td>
                    </tr>
                    <tr>
                        <td>MARGINE</td>
                        <td>{cost.margin}€</td>
                    </tr>
                    {
                        cost.requireNewCard &&
                        <tr>
                            <td>NUOVA TESSERA</td>
                            <td>{cost.newCardCost}€</td>
                        </tr>
                    }
                    <tr>
                        <th>TOTALE</th>
                        <th>{cost.total}€</th>
                    </tr>
                </tbody>
            </table>
        </>
    }

    /**
     * Render data
     * @returns 
     */
    render() {

        /**
         * if not loaded -> return empty
         */
        if(!this.state.loaded) {
            return <PageLoader />;
        }

        return <>
            <Row justify='space-between' align='middle'>
                <Col>
                    <Title>{this.isUpdate() ? 'Modifica' : 'Aggiungi'} Richiesta</Title>
                </Col>        
            </Row>


            <Form
                name="basic"
                onFinish={(data: any) => this.onFinish(data)}
                autoComplete="off"
                initialValues={{...this.state.item}}
            >
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label="Cliente"
                                name="client_id"
                                rules={[{ required: true, message: 'Campo obbligatorio' }]}
                                >
                                <Select
                                    options={this.state.cafClients.map(el => ({label: `${el.lastName} ${el.name}` , value: el.id}))}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Tipologia tessera"
                                name="card_id"
                                rules={[{ required: true, message: 'Campo obbligatorio' }]}
                                >
                                <Select
                                    options={this.state.cardsList.map(el => ({label: el.zones, value: el.id}))}
                                    onChange={(e) => this.setState({selectedZone: e})}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label="Inizio abbonamento"
                                name="fromDate"
                                rules={[{ required: !this.state.newCard, message: 'Campo obbligatorio' }]}
                                >
                                <DatePicker 
                                format={'YYYY-MM-DD'}
                                    disabledDate={d => !d || d.date() !== 1 || d.isBefore(this.state.cardStartLimit)} 
                                    onChange={e => this.setState({fromDate: e})}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>

                        <Col>
                            <Form.Item
                                name="new_card"
                                >
                                <Checkbox
                                    checked={this.state.newCard}
                                    onChange={e => this.setState({newCard: e.target.checked})}
                                >
                                    Nuova tessera?
                                </Checkbox>
                            </Form.Item>
                        </Col>

                        

                        <Col span={8}>
                            <Form.Item
                                label="Data scadenza tessera"
                                name="expireDate"
                                rules={[{ required: !this.state.newCard, message: 'Campo obbligatorio' }]}
                                >
                                <DatePicker
                                    disabled={this.state.newCard}
                                    format={'YYYY-MM-DD'}
                                    onChange={e => this.setState({expireDate: e})}
                                 />
                            </Form.Item>
                        </Col>

                        {
                            !!this.state.selectedZone &&
                            !!this.state.fromDate &&
                            ( this.state.newCard || !!this.state.expireDate) &&
                            <Col span={24}>
                                    <Alert
                                    message="Totale"
                                    description={<p>{this.getCardValue()}</p>}
                                    type="info"
                                    showIcon
                                    />
                            </Col>
                        }
                        
                    </Row>

                    <br /><br />

                    {/* <Row gutter={16}>
                        
                        <Col span={16}>
                            <Form.Item
                                label="Foto"
                                name="photoSrc"
                                >
                                <FileUploader 
                                    onUpload={(src: string) => {
                                        // Set photo value
                                        this.setState({
                                            cardPhotoUrl: src,
                                        });
                                    }}
                                    onDelete={() => {

                                        // Removeo photo
                                        this.setState({
                                            cardPhotoUrl: undefined,
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row> */}

                    <Row>
                        <Col span={8}>
                            <Form.Item>
                                <Space>
                                    <Link to='/home/requests'>
                                        <Button type='primary' danger icon={<ArrowLeftOutlined />}>Indietro</Button>
                                    </Link>
                                    <Button type='primary' htmlType="submit">Salva</Button>
                                </Space>
                                
                            </Form.Item>
                        </Col>
                    </Row>
            </Form>
            
        </>;
    }

}