import { Button, Col, Empty, Row, Space, Table, Tooltip, Typography } from "antd";
import React from "react";
import { ATMCard } from "../../services/ATMCardService";
import { SettingOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { ListLoader } from "../../components/listLoader";
import { ATMRequest, ATMRequestService } from "../../services/ATMRequestService";

const { Title } = Typography;

export class RequestList extends React.Component {

    state : {
        list: ATMCard[],
        loaded: boolean,
    } = {
        list: [],
        loaded: false,
    };

    /**
     * On component mount event
     */
    componentDidMount() {
    
        // Call Server API
        this.getList();
    }

    /**
     * Get List from Server
     */
    async getList() {
        const response = await ATMRequestService.list();
        this.setState({
            list: response,
            loaded: true,
        });
    }

    /**
     * Remove element from Server
     * @param id 
     * @returns 
     */
    async delete(id: number) {

        // Ask confirm
        if(!window.confirm('Eliminare in modo permanente?')) {
            return;
        }

        await ATMRequestService.delete(id);

        // Reload list
        await this.getList();
    }

    /**
     * Return dataTable columns
     */
    getColumns() {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Cliente',
                key: 'clientName',
                render: (element: ATMRequest) => `${element?.client?.lastName} ${element?.client?.name}`
            },
            {
                title: 'Zone',
                key: 'zones',
                render: (element: ATMRequest) => `${element?.card?.zones}`
            },
            {
                title: '#',
                key: 'other',
                render: (element: ATMRequest) => {
                    return <>
                        <Space wrap>
                            <Tooltip title="Modifica">
                                <NavLink to={`item/${element.id}`}>
                                    <Button type="primary" shape="circle" icon={<SettingOutlined />} />
                                </NavLink>
                            </Tooltip>
                            <Tooltip title="Elimina">
                                <Button danger type="primary" shape="circle" icon={<DeleteOutlined />} onClick={() => this.delete(element.id)}/>
                            </Tooltip>
                        </Space>
                    </>
                }
            }
          ];
    }


    render() {

        /**
         * if not loaded -> return empty
         */
        if(!this.state.loaded) {
            return <ListLoader />;
        }

        return <>

            <Row justify='space-between' align='middle'>
                <Col>
                    <Title>Richieste</Title>
                </Col>
                {
                    this.state.list.length > 0 &&
                    <Col>
                        <NavLink to={`item`}>
                            <Button type="primary" icon={<PlusOutlined />}>Crea</Button>
                        </NavLink>
                    </Col>
                }
                
            </Row>
            

            {
                this.state.list.length > 0 &&
                <Table columns={this.getColumns()} dataSource={this.state.list} />
            }

            {
                this.state.list.length === 0 &&
                <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{ height: 60 }}
                    description={
                    <span>
                        Non sono presenti dati
                    </span>
                    }
                >
                    <NavLink to={`item`}>
                        <Button type="primary" icon={<PlusOutlined />}>Crea nuovo</Button>
                    </NavLink>
                </Empty>
            }
            
        </>;
    }
}