import { ATMCard } from "./ATMCardService";
import { CafClient } from "./CafClientService";
import { HttpService } from "./HttpService";
import { convertDateToClientFormat } from "../utils/utils";
import moment from "moment";
import { User } from "./UserService";

export interface ATMRequest {
    id: number;
    client_id: number;
    card_id: number;
    fromDate: string;
    expireDate: string;
    photoSrc: string;
    client: CafClient;
    card: ATMCard;
    user: User;
}

export interface CardCost {
    cardCost: number;
    margin: number;
    requireNewCard: boolean;
    newCardCost: number;
    total: number;

}


export class ATMRequestService {


    /**
     * Get List from server
     * @returns ATMRequest[] Response
     */
    static async list(): Promise<ATMRequest[]> {
        const response = await HttpService.get('/atm/cards/requests', {});
        return response?.data;
    }

    /**
     * Get Item from server
     * @returns ATMRequest Response
     */
    static async item(id: number): Promise<ATMRequest> {
        const response = await HttpService.get(`/atm/cards/requests/item/${id}`, {});
        response.data.fromDate = convertDateToClientFormat(response.data.fromDate);

        if(response.data.expireDate) {
            response.data.expireDate = convertDateToClientFormat(response.data.expireDate);
        }
        
        return response?.data;
    }

    /**
     * Delet item from server
     * @returns boolean
     */
    static async delete(id: number) {
        const response = await HttpService.delete('/atm/cards/requests/delete', {id});
        return response?.data;
    }

    /**
     * Update item on Server
     * @returns boolean
     */
    static async update(payload: ATMRequest) {
        const response = await HttpService.post(`/atm/cards/requests/update`, payload);
        return response?.data;
    }


    /**
     * Create on Server
     * @returns boolean
     */
    static async create(payload: ATMRequest) {
        const response = await HttpService.post(`/atm/cards/requests/create`, payload);
        return response?.data;
    }

    static needNewCard(startDate: string | undefined, expireDate: string | null): boolean {
            
        if(!expireDate ) { return true; }

        // If before startdate and 1 year
        if( moment(startDate).add(1, 'year').isAfter(expireDate) ) {
            return true;
        }

        return false;
    }

    static cardCost(price: number, margin: number, startDate: string | undefined, expireDate: string | null): CardCost {

       const newCardCost  = 10;
       const needNewCard = this.needNewCard(startDate, expireDate);

       /**
        * Calculate total
        */
       let total = price + margin

       if(needNewCard) {
            total += newCardCost;
       }

        return {
            cardCost: price,
            margin: margin,
            requireNewCard: needNewCard,
            newCardCost,
            total: total,
        }
    }
}