import { CafElement } from "../pages/caf";
import { ATMRequest } from "./ATMRequestService";
import { CafClient } from "./CafClientService";
import { CafItemResponse } from "./CafService";
import { HttpService } from "./HttpService";
import { User } from "./UserService";

export interface TotalsRecord extends Omit<ATMRequest, 'client'> {
    client: TotalsClient;
}

export interface TotalsClient extends CafClient {
    caf: CafItemResponse;
}

export class TotalsService {


    /**
     * Get Totals List from server
     * @returns TotalsRecord[] Response
     */
    static async list(filters?: {cafId: string, fromDate: string, toDate: string}): Promise<TotalsRecord[]> {
         // Get params
        const getParams = '?' + new URLSearchParams(filters).toString();

        const response = await HttpService.get(`/totals${getParams}`, {});
        return response?.data;
    }
}