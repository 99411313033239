import { convertDateToClientFormat } from "../utils/utils";
import { HttpService } from "./HttpService";

export interface CafClient {
    id: number
    name: string
    lastName: string
    birthDate: string
    birthCountry: string
    sex: string
    phiscalCode: string
    documentType: string
    documentNumber: string
    email: string
    phone: string
    citizenship: string
    billing: Address | undefined
    delivery: Address | undefined
    addresses: Address[]
}

interface Address {
    address: string
    addressNumber: number
    province: string
    city: string
    postalCode: string
    type: 'billing' | 'delivery';
}


export class CafClientService {


    /**
     * Get List from server
     * @returns CafClient[] Response
     */
    static async list(): Promise<CafClient[]> {
        const response = await HttpService.get('/caf/clients', {});
        return response?.data;
    }

    /**
     * Get single Item from server
     * @returns CafClient Response
     */
    static async item(id: number): Promise<CafClient> {
        const response = await HttpService.get(`/caf/clients/item/${id}`, {});
        if(response.data.birthDate) {
            response.data.birthDate = convertDateToClientFormat(response.data.birthDate);
        }
        return response?.data;
    }

    /**
     * Delet item from server
     * @returns boolean
     */
    static async delete(id: number) {
        const response = await HttpService.delete('/caf/clients/delete', {id});
        return response?.data;
    }

    /**
     * Update item on Server
     * @returns boolean
     */
    static async update(payload: CafClient) {
        const response = await HttpService.post(`/caf/clients/update`, payload);
        return response?.data;
    }


    /**
     * Create on Server
     * @returns boolean
     */
    static async create(payload: CafClient) {
        const response = await HttpService.post(`/caf/clients/create`, payload);
        return response?.data;
    }
}