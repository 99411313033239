import { HttpService } from "./HttpService";

export interface UserGroup {
    id: number;
    name: string;
    type: 'caf' | 'admdin';
}

export class UserGroupService {


    /**
     * Get CAF List from server
     * @returns UserGroup[] Response
     */
    static async list(): Promise<UserGroup[]> {
        const response = await HttpService.get('/users/groups', {});
        return response?.data;
    }

    /**
     * Get CAF Item from server
     * @returns UserGroup Response
     */
    static async item(id: number): Promise<UserGroup[]> {
        const response = await HttpService.get(`/users/groups/item/${id}`, {});
        return response?.data;
    }

    /**
     * Delet CAF from server
     * @returns boolean
     */
    static async delete(id: number) {
        const response = await HttpService.delete('/users/groups/delete', {id});
        return response?.data;
    }

    /**
     * Update on Server
     * @returns boolean
     */
    static async update(payload: UserGroup) {
        const response = await HttpService.post(`/users/groups/update`, payload);
        return response?.data;
    }


    /**
     * Create on Server
     * @returns boolean
     */
    static async create(payload: UserGroup) {
        const response = await HttpService.post(`/users/groups/create`, payload);
        return response?.data;
    }
}